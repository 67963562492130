import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    border-left: none !important;
    border-right: none !important;
  }
`

const Container = props => {
  return <Wrapper>{props.children}</Wrapper>
}

export default Container
